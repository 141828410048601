/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { encode } from '../../common/helpers/helpers';
import Recaptcha, { ReCAPTCHA } from "react-google-recaptcha";
import MockRecaptcha from '../../components/MockRecaptcha';
import HomeContainer from './HomeContainer';

const TIPOS_SOCIETARIOS_HREF = "/tiposSocietarios"
const IWANTMYSAS_HREF = "/quieroMiSas"

const ContactSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required'),
});
const CONTACT_FORM_NAME = "contact"

const HomePage: FC = () => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const sitekey =
    process.env.SITE_RECAPTCHA_KEY ||
    process.env.REACT_APP_SITE_RECAPTCHA_KEY ||
    "";
  const [captchaOk, setCaptchaOk] = useState(false);

  const handleSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
    try {
      // For Netlify forms
      const recaptchaValue = recaptchaRef?.current?.getValue();
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": CONTACT_FORM_NAME,
          "g-recaptcha-response": recaptchaValue,
          ...values,
        }),
      });
      await fetch("./.netlify/functions/triggerContactEmail", {
        method: "POST",
        body: JSON.stringify({
          name: values.name,
          email: values.email,
          subject: values.subject,
          message: values.message,
        }),
      });
      resetForm();
      // Show success message
      const sentMessage = document.querySelector('.sent-message');
      if (sentMessage) sentMessage.classList.add('d-block');
    } catch (error) {
      console.error('Error en el envío del formulario de Contacto:', error);
      // Show error message
      const errorMessage = document.querySelector('.error-message');
      if (errorMessage) {
        errorMessage.textContent = 'Ocurrió un error. Por favor, intenta nuevamente.';
        errorMessage.classList.add('d-block');
      }
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <HomeContainer>
      <>
        <section id="inicio" className="hero section">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center" data-aos="zoom-out">
                <h1>CONSTITUÍ TU S.A.S.</h1>
                <h2>EN LA PROVINCIA DE CÓRDOBA</h2>
                <h3>FUNCIONAL EN TODO EL PAÍS.</h3>
                <p></p>
                <div className="d-flex">
                  <a href={IWANTMYSAS_HREF} className="cta-btn">QUIERO MI S.A.S.</a>
                  <a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" className="glightbox btn-watch-video d-flex align-items-center"> </a>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-out" data-aos-delay="200">
                <img src="assets/img/head3.svg" className="img-fluid animated" alt="" />
              </div>
            </div>
          </div>

        </section>


        <section id="beneficios" className="about section">

          <div className="container section-title" data-aos="fade-up">
            <h2>Beneficios</h2>
            <p>Constituir tu S.A.S. en Córdoba es extremadamente fácil, rápido y accesible.</p>
          </div>

          <div className="container">

            <div className="row gy-5">

              <div className="content col-xl-5 d-flex flex-column" data-aos="fade-up" data-aos-delay="100">
                <h3>¿Por qué en la provincia de Córdoba?</h3>
                <p>
                  La Provincia de Córdoba se destaca por la celeridad y facilidad en la constitución de sociedades permitiendo la obtención de la CUIT y la Matrícula de Inscripción en un plazo sumamente expedito, 100% online y con bajos costos.
                </p>
                <a href={IWANTMYSAS_HREF} className="about-btn align-self-center align-self-xl-start"><span>Quiero mi S.A.S.</span> <i className="bi bi-chevron-right"></i></a>
              </div>

              <div className="col-xl-7" data-aos="fade-up" data-aos-delay="200">
                <div className="row gy-4">

                  <div className="col-md-6 icon-box position-relative">
                    <i className="bi bi-speedometer"></i>
                    <h4><a className="stretched-link">Rápido</a></h4>
                    <p>Desde solicitada la inscripción, tu S.A.S. no demorará más de 5 días hábiles en contar con CUIT, Matrícula y estar 100% operativa.</p>
                  </div>

                  <div className="col-md-6 icon-box position-relative">
                    <i className="bi bi-rocket"></i>
                    <h4><a className="stretched-link">Fácil</a></h4>
                    <p>Una vez completado el formulario con los datos requeridos, estaremos listos para iniciar el trámite. Utilizamos un estatuto modelo pre-aprobado, lo que asegura evitar observaciones por parte del órgano de contralor.</p>
                  </div>

                  <div className="col-md-6 icon-box position-relative">
                    <i className="bi bi-piggy-bank"></i>
                    <h4><a className="stretched-link">Económico</a></h4>
                    <p>Los costos de una S.A.S. son notablemente inferiores en comparación con otros tipos societarios, como las S.A. y las S.R.L. Además, nuestros honorarios son imbatibles.</p>
                  </div>

                  <div className="col-md-6 icon-box position-relative">
                    <i className="bi bi-laptop"></i>
                    <h4><a className="stretched-link">100% Digital</a></h4>
                    <p>Toda la documentación requerida puede ser firmada digitalmente. Además, la S.A.S. debe llevar registros societarios y contables en formato digital.</p>
                  </div>

                </div>
              </div>

            </div>

          </div>

        </section>


        {/* <!-- Services Section --> */}
        <section id="procedimiento" className="services section">

          {/* <!-- Section Title --> */}
          <div className="container section-title" data-aos="fade-up">
            <h2>Procedimiento</h2>
            <p>Te explicamos cómo es el paso a paso para obtener tu S.A.S.</p>
          </div>
          {/* <!-- End Section Title --> */}

          <div className="container">

            <div className="row gy-4">

              <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                <div className="service-item position-relative">
                  <i className="bi bi-1-square-fill"></i>
                  <h4><a className="stretched-link">Completá el Formulario</a></h4>
                  <p>Ingresá a "Quiero mi S.A.S." y completá el Formulario que nos permitirá obtener toda la información necesaria.</p>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="200">
                <div className="service-item position-relative">
                  <i className="bi bi-2-square-fill"></i>
                  <h4><a className="stretched-link">Reserva de Denominación</a></h4>
                  <p>Con los nombres que nos hubieras proporcionado intentaremos reservar la denominación social de tu S.A.S. Te avisaremos cual será la definitiva.</p>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="300">
                <div className="service-item position-relative">
                  <i className="bi bi-3-square-fill"></i>
                  <h4><a className="stretched-link">Depósito del 25% del Capital Social</a></h4>
                  <p>Se abrirá una cuenta bancaria especialmente a los efectos de depositar el 25% del capital social de tu S.A.S. Nos pondremos en contacto para coordinarlo.</p>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="400">
                <div className="service-item position-relative">
                  <i className="bi bi-4-square-fill"></i>
                  <h4><a className="stretched-link">Pago de Tasa Retributiva</a></h4>
                  <p>Te enviaremos un enlace para que puedas abonar la tasa retributiva de servicios que exige la DIPJ.</p>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="500">
                <div className="service-item position-relative">
                  <i className="bi bi-5-square-fill"></i>
                  <h4><a className="stretched-link">Revisión de documentos</a></h4>
                  <p>Se te remitirá un borrador del Acta Constitutiva y Estatuto para validar que la información proporcionada sea correcta.</p>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="600">
                <div className="service-item position-relative">
                  <i className="bi bi-6-square-fill"></i>
                  <h4><a className="stretched-link">Firma y certificación de documentos</a></h4>
                  <p>Te remitiremos los documentos definitivos con las instrucciones de firma y certificaciones requeridas.</p>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="700">
                <div className="service-item position-relative">
                  <i className="bi bi-7-square-fill"></i>
                  <h4><a className="stretched-link">Ingreso del trámite de inscripción</a></h4>
                  <p>Una vez firmados los documentos, deberás remitirlos digitalizados en .pdf para su carga y presentación ante el órgano de contralor.</p>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="800">
                <div className="service-item2 position-relative">
                  <i className="bi bi-check-square" style={{ color: "#ffffff" }}></i>
                  <h4><a className="stretched-link">CUIT y Resolución de Inscripción</a></h4>
                  <p>La DIPJ analizará el pedido de Inscripción y procederá a otorgar la CUIT y a emitir la Resolución de Inscripción de tu S.A.S.</p>
                </div>
              </div>
            </div>

          </div>

        </section>

        <section id="call-to-action" className="call-to-action section">

          <img src="assets/img/cta-bg.jpg" alt="" />

          <div className="container">
            <div className="row justify-content-center" data-aos="zoom-in" data-aos-delay="100">
              <div className="col-xl-10">
                <div className="text-center">
                  <h3>Convertí tu idea de negocio en una realidad</h3>
                  <p>Con tan solo completar el Formulario podremos ayudarte a hacer realidad tu negocio. Toda la información que allí te solicitamos es estrictamente confidencial y se utilizará únicamente para la constitución de tu Sociedad.</p>
                  <a className="cta-btn" href={IWANTMYSAS_HREF}>QUIERO MI S.A.S.</a>
                </div>
              </div>
            </div>
          </div>

        </section>


        <section id="planes" className="pricing section">

          <div className="container section-title" data-aos="fade-up">
            <h2>Planes</h2>
            <p>Ofrecemos distintos Servicios según las necesidades que tengas.</p>
          </div>

          <div className="container">

            <div className="row gy-4">

              <div className="col-lg-4" data-aos="zoom-in" data-aos-delay="100">
                <div className="pricing-item">
                  <h3>Básico</h3>
                  <h4><sup>$</sup>220.000 <sup>+ gastos</sup></h4>
                  <ul>
                    <li><i className="bi bi-check"></i> <span>Constitución de Sociedad</span></li>
                    <li><i className="bi bi-check"></i> <span>Obtención de CUIT</span></li>
                    <li><i className="bi bi-check"></i> <span>Guía de uso de Libros Digitales</span></li>
                    <li className="na"><i className="bi bi-x"></i> <span>Constitución de Domicilio</span></li>
                    <li className="na"><i className="bi bi-x"></i> <span>Altas impositivas</span></li>
                  </ul>
                  <a href={IWANTMYSAS_HREF} className="buy-btn">QUIERO MI S.A.S.</a>
                </div>
              </div>

              <div className="col-lg-4" data-aos="zoom-in" data-aos-delay="200">
                <div className="pricing-item featured">
                  <h3>Emprendedor</h3>
                  <h4><sup>$</sup>260.000 <sup>+ gastos</sup><span></span></h4>
                  <ul>
                    <li><i className="bi bi-check"></i> <span>Constitución de Sociedad</span></li>
                    <li><i className="bi bi-check"></i> <span>Obtención de CUIT</span></li>
                    <li><i className="bi bi-check"></i> <span>Guía de uso de Libros Digitales</span></li>
                    <li className="na"><i className="bi bi-x"></i> <span>Constitución de Domicilio</span></li>
                    <li><i className="bi bi-check"></i> <span>Lista para facturar</span></li>
                  </ul>
                  <a href={IWANTMYSAS_HREF} className="buy-btn">QUIERO MI S.A.S.</a>
                </div>
              </div>

              <div className="col-lg-4" data-aos="zoom-in" data-aos-delay="300">
                <div className="pricing-item">
                  <h3>Premium</h3>
                  <h4><sup>$</sup>320.000 <sup>+ gastos</sup><span></span></h4>
                  <ul>
                    <li><i className="bi bi-check"></i> <span>Constitución de Sociedad</span></li>
                    <li><i className="bi bi-check"></i> <span>Obtención de CUIT</span></li>
                    <li><i className="bi bi-check"></i> <span>Alta de Libros Digitales y Guía de uso</span></li>
                    <li><i className="bi bi-check"></i> <span>Constitución de Domicilio</span></li>
                    <li><i className="bi bi-check"></i> <span>Lista para facturar</span></li>
                  </ul>
                  <a href={IWANTMYSAS_HREF} className="buy-btn">QUIERO MI S.A.S.</a>
                </div>
              </div>

            </div>

          </div>

        </section>

        <section id="costos-no-incluidos" className="additional-costs section">
          <div className="container section-title" data-aos="fade-up">
            <h2>Gastos</h2>
            <p>La constitución de tu SAS tiene algunos costos asociados que no están incluidos en ninguno de nuestros planes y deben ser considerados aparte.<br /> Se te indicará en el momento oportuno cómo abonar los mismos.</p>
          </div>

          <div className="container">
            <div className="table-responsive" data-aos="fade-up" data-aos-delay="100">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Descripción</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Tasa Retributiva de Servicios de IPJ</td>
                    <td>$116.810</td>
                  </tr>
                  <tr>
                    <td>Tasa para Reserva de Denominación</td>
                    <td>$12.970</td>
                  </tr>
                  <tr>
                    <td >Depósito del 25% del Capital Social. <a className="font-style: fst-italic">Será reintegrado dentro de las 72hs de constituida la Sociedad.</a></td>
                    <td>A determinar</td>
                  </tr>
                  <tr>
                    <td>Certificaciones de Firma</td>
                    <td>Consultar con Escribano</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>


        <section id="servicios" className="features section">

          <div className="container section-title" data-aos="fade-up">
            <h2>Otros Servicios</h2>
            <p>Además ofrecemos una gran variedad de Servicios Jurídicos y Contables para tu Sociedad</p>
          </div>

          <div className="container">

            <div className="row gy-4">

              <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="100">
                <div className="features-item">
                  <i className="bi bi-file-earmark-plus" style={{ color: "#ffbb2c" }}></i>
                  <h3><a href="#contact" className="stretched-link">Reformas de Estatuto</a></h3>
                </div>
              </div>

              <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="200">
                <div className="features-item">
                  <i className="bi bi-journal-plus" style={{ color: "#5578ff" }}></i>
                  <h3><a href="#contact" className="stretched-link">Confección de Actas</a></h3>
                </div>
              </div>

              <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="300">
                <div className="features-item">
                  <i className="bi bi-people" style={{ color: "#e80368" }}></i>
                  <h3><a href="#contact" className="stretched-link">Designación de Autoridades</a></h3>
                </div>
              </div>

              <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="400">
                <div className="features-item">
                  <i className="bi bi-person-x" style={{ color: "#e361ff" }}></i>
                  <h3><a href="#contact" className="stretched-link">Renuncia de Autoridades</a></h3>
                </div>
              </div>

              <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="500">
                <div className="features-item">
                  <i className="bi bi-123" style={{ color: "#47aeff" }}></i>
                  <h3><a href="#contact" className="stretched-link">Tratamiento de Estados Contables</a></h3>
                </div>
              </div>

              <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="600">
                <div className="features-item">
                  <i className="bi bi-house-add" style={{ color: "#ffa76e" }}></i>
                  <h3><a href="#contact" className="stretched-link">Cambio de Sede Social</a></h3>
                </div>
              </div>

              <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="700">
                <div className="features-item">
                  <i className="bi bi-coin" style={{ color: "#10C014" }}></i>
                  <h3><a href="#contact" className="stretched-link">Aumentos de Capital Social</a></h3>
                </div>
              </div>

              <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="800">
                <div className="features-item">
                  <i className="bi bi-intersect" style={{ color: "#4233ff" }}></i>
                  <h3><a href="#contact" className="stretched-link">Fusiones</a></h3>
                </div>
              </div>

              <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="900">
                <div className="features-item">
                  <i className="bi bi-x-circle" style={{ color: "#B0090C" }}></i>
                  <h3><a href="#contact" className="stretched-link">Disolución/Liquidación</a></h3>
                </div>
              </div>

              <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="1000">
                <div className="features-item">
                  <i className="bi bi-signpost-split" style={{ color: "#5C62C9" }}></i>
                  <h3><a href="#contact" className="stretched-link">Escisión</a></h3>
                </div>
              </div>

              <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="1100">
                <div className="features-item">
                  <i className="bi bi-activity" style={{ color: "#ff5828" }}></i>
                  <h3><a href="#contact" className="stretched-link">Transformación</a></h3>
                </div>
              </div>

              <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="1200">
                <div className="features-item">
                  <i className="bi bi-building-exclamation" style={{ color: "#29cc61" }}></i>
                  <h3><a href="#contact" className="stretched-link">Compra/Venta de Sociedades</a></h3>
                </div>
              </div>

            </div>

          </div>

        </section>


        <section id="faq" className="faq section">

          <div className="container section-title" data-aos="fade-up">
            <h2>Preguntas Frecuentes</h2>
            <p></p>
          </div>

          <div className="container">

            <div className="row faq-item" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-5 d-flex">
                <i className="bi bi-question-circle"></i>
                <h4>No soy de Córdoba, ¿igual puedo constituir una S.A.S. en Córdoba?</h4>
              </div>
              <div className="col-lg-7">
                <p>Sí. La única salvedad que deberás tener en cuenta es que la sede social de tu S.A.S. deberá estar fijada dentro de la provincia de Córdoba. Si no dispones de un domicilio donde fijarla, contáctate con nosotros para que podamos ayudarte.</p>
              </div>
            </div>

            <div className="row faq-item" data-aos="fade-up" data-aos-delay="200">
              <div className="col-lg-5 d-flex">
                <i className="bi bi-question-circle"></i>
                <h4>¿Qué diferencias hay entre una S.A.S., S.A. y S.R.L.?</h4>
              </div>
              <div className="col-lg-7">
                <p>
                  Si bien los tres tipos societarios son plenamente útiles y eficaces para desarrollar un negocio o actividad, la S.A.S. permite una mayor flexibilidad y simpleza en su constitución y posterior desenvolvimiento. Si te interesa saber en detalle las principales diferencias entre cada uno de los tipos societarios más utilizados en nuestro país, te invitamos a leer el siguiente informe: <a href={TIPOS_SOCIETARIOS_HREF}>VER INFORME</a>.
                </p>
              </div>
            </div>

            <div className="row faq-item" data-aos="fade-up" data-aos-delay="300">
              <div className="col-lg-5 d-flex">
                <i className="bi bi-question-circle"></i>
                <h4>¿Qué tipo de actividad o negocios puedo realizar con una S.A.S.?</h4>
              </div>
              <div className="col-lg-7">
                <p>
                  La S.A.S. tiene la posibilidad de prever múltiples actividades posibles para desarrollar. Se puede optar entre un objeto múltiple pre-aprobado por el órgano de contralor o diseñar uno en concreto para la Sociedad. No podrán ser objeto de una S.A.S.: a) operaciones de capitalización, ahorro o en cualquier forma requieran dinero o valores al público con promesas de prestaciones o beneficios futuros; b) explotación de concesión o servicios públicos.
                </p>
              </div>
            </div>

            <div className="row faq-item" data-aos="fade-up" data-aos-delay="400">
              <div className="col-lg-5 d-flex">
                <i className="bi bi-question-circle"></i>
                <h4>¿Puedo ser accionista o administrador de una S.A.S. siendo extranjero?</h4>
              </div>
              <div className="col-lg-7">
                <p>
                  Sí. De todas maneras, atento a tratarse de un supuesto de excepción, el procedimiento y los requisitos a cumplimentar pueden variar del trámite regular. En caso de que seas extranjero y desees formar parte de una S.A.S. te invitamos a contactarte con nosotros para darte una atención personalizada y evaluar tu caso.
                </p>
              </div>
            </div>

            <div className="row faq-item" data-aos="fade-up" data-aos-delay="500">
              <div className="col-lg-5 d-flex">
                <i className="bi bi-question-circle"></i>
                <h4>¿Puedo constituir una S.A.S. unipersonal?</h4>
              </div>
              <div className="col-lg-7">
                <p>
                  Sí. Una S.A.S. puede ser constituida con una sola persona como socia. De todas maneras, necesitarás de una segunda persona para llenar los cargos del órgano de administración de la sociedad, sin necesidad que esa persona sea además socia de la Sociedad.
                </p>
              </div>
            </div>

            <div className="row faq-item" data-aos="fade-up" data-aos-delay="500">
              <div className="col-lg-5 d-flex">
                <i className="bi bi-question-circle"></i>
                <h4>¿Cuál es el capital mínimo requerido para constituir una S.A.S.?</h4>
              </div>
              <div className="col-lg-7">
                <p>
                  El capital social no podrá ser inferior al importe equivalente a dos (2) veces el salario mínimo vital y móvil que se establezca al momento de su constitución.
                </p>
              </div>
            </div>

            <div className="row faq-item" data-aos="fade-up" data-aos-delay="500">
              <div className="col-lg-5 d-flex">
                <i className="bi bi-question-circle"></i>
                <h4>¿Cómo funcionan los Libros Digitales?</h4>
              </div>
              <div className="col-lg-7">
                <p>
                  Las S.A.S. llevan obligatoriamente Libros Digitales donde deberán asentarse todos los actos de la Sociedad. Su acceso se realiza a través de un Portal dispuesto por la autoridad de contralor y desde allí se pueden visualizar y cargar todas las actas y documentos pertinentes, permaneciendo los mismos inalterables en una nube de acceso único para los administradores de la Sociedad y/o quienes ellos designen a tales efectos. Si deseas mayor asesoramiento respecto a libros digitales, te solicitamos te comuniques con nosotros para poder ayudarte.
                </p>
              </div>
            </div>

            <div className="row faq-item" data-aos="fade-up" data-aos-delay="500">
              <div className="col-lg-5 d-flex">
                <i className="bi bi-question-circle"></i>
                <h4>¿Es posible redactar mis propias previsiones estatutarias?</h4>
              </div>
              <div className="col-lg-7">
                <p>
                  Sí. En caso que desees un estatuto confeccionado de acuerdo a necesidades específicas, te pedimos nos contactes para poder evaluar el asunto e informarte los pasos a seguir.
                </p>
              </div>
            </div>

          </div>

        </section>

        <section id="contact" className="contact section">

          <div className="container section-title" data-aos="fade-up">
            <h2>Contacto</h2>

          </div>

          <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">

            <div className="row gy-4">

              <div className="col-lg-5">
                <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="200">
                  <i className="bi bi-geo-alt flex-shrink-0"></i>
                  <div>
                    <h3>Dirección</h3>
                    <p>Ituzaingó 87, 4to y 5to Piso, Córdoba, Argentina</p>
                  </div>
                </div>

                <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
                  <i className="bi bi-telephone flex-shrink-0"></i>
                  <div>
                    <h3>Teléfono</h3>
                    <p>+54 9 351 428 4037</p>
                  </div>
                </div>

                <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
                  <i className="bi bi-envelope flex-shrink-0"></i>
                  <div>
                    <h3>Email</h3>
                    <p>contacto@quieromisas.com</p>
                  </div>
                </div>

              </div>

              <div className="col-lg-7">
                <Formik
                  initialValues={{ name: '', email: '', subject: '', message: '' }}
                  validationSchema={ContactSchema}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting, isValid, dirty }) => (
                    <Form name={CONTACT_FORM_NAME} className="php-email-form" data-aos="fade-up" data-aos-delay="500">
                      <input type="hidden" name="form-name" value={CONTACT_FORM_NAME} />
                      <div className="row gy-4">
                        <div className="col-md-6">
                          <Field type="text" name="name" className="form-control" placeholder="Nombre y Apellido" />
                          <ErrorMessage name="name" component="div" className="invalid-feedback" />
                        </div>

                        <div className="col-md-6">
                          <Field type="email" name="email" className="form-control" placeholder="Email" />
                          <ErrorMessage name="email" component="div" className="invalid-feedback" />
                        </div>

                        <div className="col-md-12">
                          <Field type="text" name="subject" className="form-control" placeholder="Asunto" />
                          <ErrorMessage name="subject" component="div" className="invalid-feedback" />
                        </div>

                        <div className="col-md-12">
                          <Field as="textarea" name="message" className="form-control" rows={6} placeholder="Mensaje" />
                          <ErrorMessage name="message" component="div" className="invalid-feedback" />
                        </div>

                        {sitekey ? (
                          <Recaptcha
                            sitekey={sitekey}
                            size="normal"
                            id="recaptcha-google-contact"
                            onChange={() => setCaptchaOk(true)}
                            style={{ display: 'flex', justifyContent: 'center' }}
                          />
                        ) : (
                          <MockRecaptcha onChange={() => setCaptchaOk(true)} />
                        )}

                        <div className="col-md-12 text-center">
                          <div className="loading" style={{ display: isSubmitting ? 'block' : 'none' }}>Cargando</div>
                          <div className="error-message"></div>
                          <div className="sent-message">Tu mensaje fue enviado. Muchas gracias!</div>

                          <button type="submit" disabled={isSubmitting || !isValid || !dirty || !captchaOk}>ENVIAR</button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>

            </div>

          </div>

        </section>
      </>
    </HomeContainer>
  );
};

export default HomePage;
